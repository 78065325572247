import i18n from '../i18n';
import { SERVICER, CUSTOMERS, DASHBOARD, DEVICES, SITE, FIRMWARES } from './urlConstant';

export const DashboardNav = {
    _tag: 'CSidebarNavItem',
    name: i18n.t('dashboard'),
    to: DASHBOARD,
    icon: 'ri-dashboard-3-line',
    activeIcon: 'ri-dashboard-3-fill',
};

const _nav = [
    DashboardNav,
    {
        _tag: 'CSidebarNavItem',
        name: i18n.t('devices'),
        to: DEVICES,
        icon: 'ri-device-line',
        activeIcon: 'ri-device-fill',
    },
];

export const SiteNav = {
    _tag: 'CSidebarNavItem',
    name: i18n.t('site_navigation'),
    to: SITE,
    icon: 'ri-node-tree',
    activeIcon: 'ri-node-tree-fill',
};

export const servicer_nav = [
    {
        _tag: 'CSidebarNavItem',
        name: i18n.t('servicers'),
        to: SERVICER,
        icon: 'ri-user-shared-line',
        activeIcon: 'ri-user-shared-fill',
    },
];
export const _super_nav = [
    {
        _tag: 'CSidebarNavItem',
        name: i18n.t('firmwares'),
        to: FIRMWARES,
        icon: 'ri-file-zip-line',
        activeIcon: 'ri-file-zip-fill',
    },
    {
        _tag: 'CSidebarNavItem',
        name: i18n.t('customers'),
        to: CUSTOMERS,
        icon: 'ri-user-line',
        activeIcon: 'ri-user-fill',
    },
];

export default _nav;
