import { CToast, CToaster, CToastHeader } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducers';
import { ToastVariant } from 'shared/utils/Constants';

interface ToasterI {
    autohide: boolean | number;
    closeButton: boolean;
    fade: boolean;
    variant: ToastVariant;
    message: string;
}

const autohide = true;
const autohideValue = 5000;
const closeButton = true;
const fade = true;
const Toaster = () => {
    const toastState = useSelector((state: AppState) => state.toastStateReducer);
    const [toasts, setToasts] = useState<ToasterI[]>([]);

    const addToast = (variant: ToastVariant, message: string) => {
        setToasts([...toasts, { autohide: autohide && autohideValue, closeButton, fade, variant, message }]);
    };

    useEffect(() => {
        if (toastState && toastState.variant) {
            addToast(toastState.variant, toastState.message);
        }
    }, [toastState]);

    return (
        <CToaster position="top-right" style={{ zIndex: 9999999 }}>
            {toasts.map((toast: ToasterI) => {
                return (
                    <CToast
                        color={toast.variant}
                        key={'toast' + `${toast.message}` + `${toast.variant}`}
                        show={true}
                        autohide={toast.autohide}
                        fade={toast.fade}
                    >
                        <CToastHeader
                            className={`${toast.variant} font-body p-3 text-dark`}
                            closeButton={toast.closeButton}
                        >
                            {toast.message}
                        </CToastHeader>
                    </CToast>
                );
            })}
        </CToaster>
    );
};

export default Toaster;
